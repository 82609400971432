/* eslint-disable react/require-default-props */
import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';

import Button from './button';
import ModalDialog from './ModalDialog';
import { ReactComponent as IconDelete } from '../assets/icon-delete.svg';
import { breakpointLarge, unit } from '../styles';
import { urlManager } from 'utils/urlManager';
import { useTranslation } from 'react-i18next';
import { PostMessageProcessing } from 'utils/postmessage';
import { ITheme, IVisit, TEditBlockCss } from 'data-layer/types';
import { openWidget } from './WidgetModal';
import {
  getVisit,
  getMultilineTranslation,
  getAppointmentStart,
  isClientCancellationForbidden,
} from 'utils/utils';
import { navigation, ClientContext } from 'utils';
import { StartConferenceButton } from './StartConferenceButton';
import { Appointment } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { useCancelAppointment } from 'shared/api/appointment';

interface AppointmentEditBlockProps {
  appointmentData: Appointment;
  css: TEditBlockCss;
  className?: string;
  refetchFn?: (() => unknown) | undefined;
  goHomeAfterCancel?: boolean;
}

export const AppointmentEditBlock: React.FC<AppointmentEditBlockProps> = (
  props: AppointmentEditBlockProps,
) => {
  const clientContext = useContext(ClientContext);
  const { theme } = clientContext;
  const { t } = useTranslation();
  const { appointmentData, css, className, refetchFn, goHomeAfterCancel = false } = props;
  const { appointment, resource, taxonomy, client, business, telemedData } = appointmentData;
  const { id, start } = appointment;
  const businessID = business.id;
  const appStart = getAppointmentStart(appointmentData, clientContext.timezone);
  const { appStarted, appSoonStart, appInPast } = appStart;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [appIsCancelled, setAppIsCancelled] = useState(false);
  const [tryToCancel, touchCancel] = useState(false);
  const [cancelError, setCancelError] = useState(false);
  let appointmentPM: PostMessageProcessing;
  const visit: IVisit = getVisit(appointmentData);
  const isTelemed = !!telemedData?.joinUrl;
  const isCancellationForbidden = isClientCancellationForbidden(start.toString(), clientContext);

  function changeAppHandler() {
    const pmConfig = {
      visit,
      unsubscribeOnClose: true,
    };

    appointmentPM = new PostMessageProcessing(pmConfig);
    appointmentPM.subscribe();
    openWidget({
      networkId: urlManager.getNetworkId(),
      business: businessID,
      resourceId: resource.id,
      taxonomy: taxonomy.id,
    });
  }

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
    touchCancel(false);
    if (appIsCancelled && refetchFn) {
      refetchFn();
      setAppIsCancelled(false);
    }
    if (goHomeAfterCancel) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigation.goBack();
    }
  }

  const { error: cancelAppointmentError, isLoading: cancelAppointmentIsLoading, mutate: sendCancelAppointment } = useCancelAppointment();

  function cancelAppointment() {
    touchCancel(true);
    return sendCancelAppointment(
      id,
      client.shortId ?? '',
    ).then((response) => {
      if (response) {
        setAppIsCancelled(true);
      } else {
        setCancelError(true);
      }
    }).catch(() => {
      setCancelError(true)
    });
  }

  function cancelPressedText() {
    if (cancelError || !!cancelAppointmentError) {
      return <CancelText>{t('modals.appointmentCancel.failCancelText')}</CancelText>;
    }
    if (cancelAppointmentIsLoading) {
      return <CancelText>{t('modals.appointmentCancel.processCancelText')}</CancelText>;
    }
    return <CancelText>{t('modals.appointmentCancel.successCancelText')}</CancelText>;
  }

  function renderDialog() {
    const canChange = !(isTelemed && appSoonStart);
    let title;
    let confirmButtonTitle;
    let changeButtonTitle;
    let onConfirm;
    let onChange;
    let content;
    if (tryToCancel) {
      content = cancelPressedText();
    } else {
      if (canChange) {
        content = <div>{getMultilineTranslation(t('modals.appointmentCancel.text'))}</div>;
        changeButtonTitle = t('modals.appointmentCancel.bntChangeText');
        onChange = () => {
          closeModal();
          changeAppHandler();
        };
      } else {
        content = <div>{getMultilineTranslation(t('modals.appointmentCancel.textTelemed'))}</div>;
      }
      title = t('modals.appointmentCancel.title');
      confirmButtonTitle = t('modals.appointmentCancel.bntCancelText');
      onConfirm = cancelAppointment;
    }
    return (
      <ModalDialog
        isOpen={modalIsOpen}
        title={title}
        contentLabel={title}
        confirmButtonTitle={confirmButtonTitle}
        cancelButtonTitle={t('modals.appointmentCancel.bntBackText')}
        changeButtonTitle={changeButtonTitle}
        onConfirm={onConfirm}
        onCancel={closeModal}
        onChange={onChange}
        onRequestClose={closeModal}
      >
        {content}
      </ModalDialog>
    );
  }

  function renderStartBtn() {
    return isTelemed && (appSoonStart || appStarted) ? (
      <StartConferenceButton
        joinUrl={telemedData?.joinUrl || ''}
        cssType={css}
        appStart={appStart}
      />
    ) : (
      <ButtonStyled theme={theme} css={css} type="button" onClick={changeAppHandler}>
        {t('components.appointmentBlock.change')}
      </ButtonStyled>
    );
  }

  function showEditBlock() {
    return (
      <>
        <Container css={css} className={className}>
          {renderStartBtn()}
          {isCancellationForbidden || (
            <ButtonStyled
              theme={theme}
              css={css}
              variant="transparent"
              type="button"
              onClick={openModal}
            >
              <IconDelete />
              {t('components.appointmentBlock.cancel')}
            </ButtonStyled>
          )}
        </Container>
        {renderDialog()}
      </>
    );
  }
  return appStarted || appSoonStart || !appInPast ? showEditBlock() : null;
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

interface IProps {
  css: TEditBlockCss;
  theme: ITheme;
}

const Container = styled('div')(({ css }: IProps) => {
  switch (css) {
    case 'page':
      return {
        display: 'inline-block',
      };
    case 'card':
    default:
      return {
        display: 'flex',
        marginTop: unit * 2,
      };
  }
});

const ButtonStyled = styled(Button)(({ css, theme }: IProps) => {
  const style = {
    minWidth: '50%',
    padding: unit * 1.3,
    svg: {
      width: unit * 2,
      height: unit * 2,
    },
  };

  switch (css) {
    case 'page':
      return {
        display: 'inline-block',
        fontWeight: 900,
        fontSize: 14,
        lineHeight: 'inherit',
        minWidth: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
        color: theme.mainColor,
        '&, &:hover': {
          background: 'none',
        },
        '&:hover': {
          opacity: 0.8,
        },
        '&:not(:last-child)': {
          [`[dir="ltr"] &`]: {
            marginRight: unit * 4,
          },
          [`[dir="rtl"] &`]: {
            marginLeft: unit * 4,
          },
        },
        svg: {
          position: 'relative',
          top: unit * 0.2,
          [`[dir="ltr"] &`]: {
            marginRight: unit,
          },
          [`[dir="rtl"] &`]: {
            marginLeft: unit,
          },
        },
      };
    case 'page-bottom':
      return {
        ...style,
        svg: {
          display: 'none',
        },
      };
    case 'card':
    default:
      return {
        ...style,
        [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
          svg: {
            display: 'none',
          },
        },
        [`@media screen and (max-width: ${breakpointLarge}px)`]: {
          '&:nth-of-type(1)': {
            display: 'none',
          },
          '&:nth-of-type(2)': {
            fontSize: 0,
            minWidth: 'auto',
            position: 'absolute',
            top: unit * 2,
            [`[dir="ltr"] &`]: {
              right: 0,
            },
            [`[dir="rtl"] &`]: {
              left: 0,
            },
          },
        },
      };
  }
});

export const LinkStyled = ButtonStyled.withComponent('a');

const CancelText = styled('div')({
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    marginTop: '40vh',
  },
});
