import { BusinessBackofficeConfigurationObject, BusinessClass, ResultClass } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

export const getDisablePasswordLogin = (
  businessInfo?: BusinessClass,
  network?: Partial<ResultClass>,
): boolean => {
  return network?.businessConfiguration?.disablePasswordLoginInClientCabinet ?? 
    businessInfo?.backoffice_configuration?.disablePasswordLoginInClientCabinet ?? 
    false;
};

export const getDisablePasswordLoginByBackofficeConfiguration = (
  backofficeConfiguration?: BusinessBackofficeConfigurationObject,
  network?: Partial<ResultClass>,
): boolean => {
  return network?.businessConfiguration?.disablePasswordLoginInClientCabinet ??
    backofficeConfiguration?.disablePasswordLoginInClientCabinet ?? 
    false;
};
