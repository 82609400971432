import React, { useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { PageContainer } from 'components';
import { useTranslation } from 'react-i18next';
import { ClientContext, config, urlManager, usePostMessage } from 'utils';
import { paths } from 'utils/routing';
import { useGetAppointments } from 'shared/api/appointment';

export interface IWidgetModalParams {
  url?: string;
  networkId?: string;
  business?: string;
  screen?: string;
  taxonomy?: string;
  resourceId?: string;
}

export const MODAL_ELEMENT_ID = 'widget-modal';

type NewRecordScreenProps = RouteComponentProps;

const NewRecordScreen: React.FC<NewRecordScreenProps> = () => {
  const { ehrCounter, businessId, networkId, contractId, contractExtraId } = useContext(ClientContext);
  const { t } = useTranslation();
  const { data: appointments } = useGetAppointments(businessId, networkId, contractId, contractExtraId)
  const ehrCounterSum = (Object.values(ehrCounter) as number[]).reduce((sum, val) => sum + val, 0);
  const title =
    appointments?.length ?? !!ehrCounterSum ? t('title.newRecord') : t('title.homeEmpty');

  usePostMessage({});

  const [widgetParams] = useState<IWidgetModalParams | undefined>({ screen: '' });

  const insertWidget = (): void | undefined => {
    if (!widgetParams) {
      return;
    }
    if (!widgetParams.business && !widgetParams.networkId) {
      const businessId = urlManager.getBusinessId();
      const networkId = urlManager.getNetworkId();
      if (networkId) {
        widgetParams.networkId = networkId;
      } else if (businessId) {
        widgetParams.business = businessId;
      }
    }
    widgetParams.url = config.REACT_APP_WIDGET_URL;
    // eslint-disable-next-line no-unused-expressions
    window.GBooking && window.GBooking.openWidget(widgetParams);
  };

  React.useEffect(() => {
    insertWidget();
  }, []);
  return (
    <PageContainer title={title} topNav backLink={paths.homePath}>
      <div id={MODAL_ELEMENT_ID}>
      </div>
    </PageContainer>
  );
};
export default NewRecordScreen;
