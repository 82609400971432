import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  USER_CRED,
  GET_PATIENT_ID, GET_EHR_APPOINTMENTS, USER_PHONE,
  GET_EHR_DIAGNOSTIC_COUNT,
  GET_NETWORK_BRANCH_LIST,
} from 'data-layer/queries';
import { UserCred } from 'data-layer/queries/__graphql__/UserCred';
import { GetEHRPatientId } from 'data-layer/queries/__graphql__/GetEHRPatientId';
import { GET_ANY_BUSINESSID } from 'data-layer/queries/geAnyBusinessID';
import { getAnyBusinessID } from 'data-layer/queries/__graphql__/getAnyBusinessID';
import {
  ClientContext,
  urlManager,
  IClientContext,
  defaultClientInfo,
  defaultContext,
  getNationalPhone,
  constants,
  defaultTheme,
  IgetResourceNameParam,
} from 'utils';
import { getLang } from '../utils/i18n';
import { GET_EHR_DIAGNOSTICS } from 'data-layer/queries/getEHRDiagnostics';
import {
  GetEHRAppointments,
  GetEHRAppointmentsVariables,
} from 'data-layer/queries/__graphql__/GetEHRAppointments';
import {
  GetEHRDiagnostics,
  GetEHRDiagnosticsVariables,
} from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import { IEHRCounter } from 'data-layer/types';
import { PatientProperties, getPatientInfoFromProfile } from 'data-layer/helpers';
import { UserPhone } from 'data-layer/queries/__graphql__/UserPhone';

import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/he';
import { GET_EHR_APPOINTMENT_RESULTS } from 'data-layer/queries/getPatientAppointmentResults';
import {
  GetEHRAppointmentResults,
  GetEHRAppointmentResultsVariables,
} from 'data-layer/queries/__graphql__/GetEHRAppointmentResults';
import { GET_EHR_APPOINTMENTS_COUNT } from 'data-layer/queries/getPatientResultsCount';
import { GetEHRAppointmentsCount, GetEHRAppointmentsCountVariables } from 'data-layer/queries/__graphql__/GetEHRAppointmentsCount';
import { GET_EHR_APPOINTMENT_RESULTS_COUNT } from 'data-layer/queries/getPatientAppointmentResultsCount';
import { GetEHRAppointmentResultsCount, GetEHRAppointmentResultsCountVariables } from 'data-layer/queries/__graphql__/GetEHRAppointmentResultsCount';
import { GetEHRDiagnosticsCount, GetEHRDiagnosticsCountVariables } from 'data-layer/queries/__graphql__/GetEHRDiagnosticsCount';
import i18n from 'utils/i18n';
import { Global } from '@emotion/core';
import globalStyles from '../styles';
import { useAuth } from './AuthProvider';
import { UserToken } from 'data-layer/types/StrictTokens';
import { Cred, Ehr } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { useGetBusiness } from 'shared/api/business/getBusiness';
import { useGetNetwork } from 'shared/api/network/getNetwork';
import { useGetClient } from 'shared/api/client/getClient';
import { useGetProfileInfo } from 'shared/api/profile';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { getNetworkBranchList, getNetworkBranchListVariables } from 'data-layer/queries/__graphql__/getNetworkBranchList';

export const ClientContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const credData = useQuery<UserCred>(USER_CRED);
  const patientData = useQuery<GetEHRPatientId>(GET_PATIENT_ID);
  const networkID = urlManager.getNetworkId();
  const anyBusinessData = useQuery<getAnyBusinessID>(GET_ANY_BUSINESSID, {
    skip: !networkID,
  });
  const businessId = urlManager.getBusinessId() || anyBusinessData.data?.anyBusinessID || '';
  const contractId = urlManager.getContractId();
  const contractExtraId = urlManager.getContractExtraId();
  const { data: businessData } = useGetBusiness(businessId);
  const phoneData = useQuery<UserPhone>(USER_PHONE);
  const nationalMode =
    businessData?.backoffice_configuration?.enablePhoneNationalMode ||
    false;
  const country =
    (phoneData.data?.phoneCountry as string) ||
    (businessData?.general_info?.address?.[0].country as string) ||
    'RU';

  const businessCountry = businessData?.general_info.address?.[0]
    .country as string;
  const lang = getLang((businessCountry || '').toLocaleLowerCase());

  moment.locale(lang.tag);

  const businessNetworkID: string = businessData?.general_info?.networkID?.toString() ?? '';
  const { data: networkData } = 
    useGetNetwork(networkID ?? businessNetworkID, contractId, contractExtraId);
  const { tokens, getToken } = useAuth();
  // TODO: remove get old token from apollo client in future
  const [userToken, setUserToken] = useState<UserToken>(
    getToken(
      networkData?.businessConfiguration?.strictLoginInClientCabinet ?? businessData?.backofficeConfiguration?.strictLoginInClientCabinet, 
      urlManager.getBusinessId(),
      networkID,
    ) ?? { user: '', token: '', expires: '' }
  );
  useEffect(() => {
    setUserToken(
      getToken(
        networkData?.businessConfiguration?.strictLoginInClientCabinet ?? businessData?.backofficeConfiguration?.strictLoginInClientCabinet, 
        urlManager.getBusinessId(),
        networkID,
      ) ?? { user: '', token: '', expires: '' }
    )
  }, [businessData, tokens])

  const user = credData.data?.user ?? userToken.user;
  const token = credData.data?.token ?? userToken.token;
  const expires = credData.data?.expires ?? userToken.expires;
  const patientId = patientData.data?.patientId || '';
  useEffect(()=> {
    const cred: Cred = { user, token };
    if (MedMeAPI.setCredentials) {
      MedMeAPI.setCredentials(cred);
    }
    refetchUser();
  }, [userToken])
  const {data: clientData, isLoading: isClientLoading, refetch: refetchClient} = useGetClient(businessId);

  const { data: networkBranchList } = useQuery<getNetworkBranchList, getNetworkBranchListVariables>(
    GET_NETWORK_BRANCH_LIST,
    {
      variables: { networkID, contractExtraId: urlManager.getContractExtraId() },
      skip: !networkID,
      fetchPolicy: 'cache-and-network',
    },
  );

  const getEhrUrl = (ehr?: Ehr) => {
    if (!ehr) {
      return '';
    }
    const { host, port, path, protocol } = ehr;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${protocol}//${host}:${port}${path}`;
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ehrEnabled = networkID
    ? networkData?.integrationData?.ehr?.active ?? false
    : businessData?.integration_data?.ehr?.active ?? false;

  const ehrEndPoint = networkID
    ? getEhrUrl(networkData?.integrationData?.ehr)
    : getEhrUrl(businessData?.integration_data?.ehr);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ehrEntities: string[] = networkID
    ? networkData?.integrationData?.ehr?.availableEntities || []
    : businessData?.integration_data?.ehr?.availableEntities || []

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const customTranslates: string = networkID
    ? networkData?.businessConfiguration?.newWidgetTheme?.clientCabinetTranslates || '{}'
    : businessData?.widget_configuration.newWidgetTheme?.clientCabinetTranslates || '{}'

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const translates: any = JSON.parse(customTranslates)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!!translates && Object.keys(translates).length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      i18n.addResourceBundle(lang.tag, 'trans', translates, true, true);
    }
  } catch (err) { }
  const { data: profileInfo, isLoading: isProfileInfoLoading, refetch: refetchProfile} = useGetProfileInfo(
    contractId,
    contractExtraId,
    { skip: !user || !clientData?.id },
  );
  const clientInfoPending = credData.loading || isClientLoading || isProfileInfoLoading;
  let clientInfo: PatientProperties = defaultClientInfo;
  if (profileInfo && clientData) {
    clientInfo = getPatientInfoFromProfile(
      profileInfo,
      clientData,
    );
  }
  const clientId = clientData?.id ?? '';
  const ehrCounter: IEHRCounter = {
    appointment: 0,
    appointmentResult: 0,
    prescription: 0,
    diagnosticReport: 0,
  };
  const context = {
    user,
    token,
    clientId,
    patientId,
    businessID: businessId,
    ehrEndPoint,
  }

  const appEHRCount = useQuery<GetEHRAppointmentsCount, GetEHRAppointmentsCountVariables>(GET_EHR_APPOINTMENTS_COUNT, {
    variables: {
      ...context,
    },
    fetchPolicy: 'network-only',
    skip: !patientId || !token || !clientId,
  })

  const appEHRCountResult = appEHRCount.data?.getPatientResultsCount
  const ehrCountResultLoading = !appEHRCount.called || (appEHRCount.called && appEHRCount.loading)

  const appEhrResultCount = useQuery<GetEHRAppointmentResultsCount, GetEHRAppointmentResultsCountVariables>(
    GET_EHR_APPOINTMENT_RESULTS_COUNT,
    {
      variables: {
        ...context,
      },
      fetchPolicy: 'network-only',
      skip: !patientId || !token || !clientId || !appEHRCountResult?.support || ehrCountResultLoading
    }
  )
  const appEhrResultsCountResult = appEhrResultCount.data?.getPatientAppointmentResultsCount
  const diagnosticEHRCount = useQuery<GetEHRDiagnosticsCount, GetEHRDiagnosticsCountVariables>(GET_EHR_DIAGNOSTIC_COUNT,
    {
      variables: {
        ...context,
      },
      fetchPolicy: 'network-only',
      skip: !patientId || !token || !clientId || !appEHRCountResult?.support || ehrCountResultLoading,
    }
  )
  const diagnosticEHRCountResult = diagnosticEHRCount.data?.getEHRDiagnosticsCount


  const appEHRData = useQuery<GetEHRAppointments, GetEHRAppointmentsVariables>(
    GET_EHR_APPOINTMENTS,
    {
      variables: {
        ...context,
        offset: 0,
        size: 200,
      },
      fetchPolicy: 'cache-and-network',
      skip: !patientId || !token || !clientId || ehrCountResultLoading || appEHRCountResult?.support
      // || !appEHRCountResult || !!appEHRCountResult?.support,
    },
  );
  const appEHRDataLoading = !appEHRData.called || (appEHRData.called && appEHRData.loading)
  const appEHRResults = useQuery<GetEHRAppointmentResults, GetEHRAppointmentResultsVariables>(
    GET_EHR_APPOINTMENT_RESULTS,
    {
      variables: {
        ...context,
        offset: 0,
        size: 200,
      },
      fetchPolicy: 'cache-and-network',
      skip: !patientId || !token || !clientId || ehrCountResultLoading || appEHRDataLoading || appEhrResultsCountResult?.support
      // || !appEhrResultsCountResult || !!appEhrResultsCountResult?.support,
    },
  );
  const appEHRResultsLoading = !appEHRResults.called || (appEHRResults.called && appEHRResults.loading)
  const diagnosticEHRData = useQuery<GetEHRDiagnostics, GetEHRDiagnosticsVariables>(
    GET_EHR_DIAGNOSTICS,
    {
      variables: {
        ...context,
        offset: 0,
        size: 200,
      },
      fetchPolicy: 'cache-and-network',
      skip: !patientId || !token || !clientId || ehrCountResultLoading || appEHRDataLoading || appEHRResultsLoading || diagnosticEHRCountResult?.support,
    },
  );

  if (appEHRCountResult?.support) {
    ehrCounter.appointmentResult = appEHRCountResult.count
  } else if (
    appEHRResults.data?.getPatientAppointmentResults.appointmentResults.length
  ) {
    ehrCounter.appointmentResult =
      appEHRResults.data?.getPatientAppointmentResults.appointmentResults.length;
  }
  if (appEhrResultsCountResult?.support) {
    ehrCounter.appointment = appEhrResultsCountResult.count
  } else if (appEHRData.data?.getPatientAppointments.appointments?.length) {
    ehrCounter.appointment = appEHRData.data?.getPatientAppointments.appointments.length || 0;
  }
  if (diagnosticEHRCountResult?.support) {
    ehrCounter.diagnosticReport = diagnosticEHRCountResult.count
  } else if (diagnosticEHRData.data?.getEHRDiagnostics.diagnostics?.length) {
    ehrCounter.diagnosticReport = diagnosticEHRData.data?.getEHRDiagnostics.diagnostics.length || 0;
  }
  const businessTimeZone =
    businessData?.general_info.timezone || defaultContext.timezone;

  if (nationalMode) {
    clientInfo.phone = getNationalPhone(clientInfo.phone, country);
  }

  let theme = defaultTheme;
  if (networkData?.businessConfiguration?.newWidgetTheme) {
    theme = Object.assign(
      defaultTheme,
      networkData?.businessConfiguration.newWidgetTheme,
    );
  } else if (!networkID && !businessNetworkID) {
    theme = Object.assign(
      defaultTheme,
      businessData?.widget_configuration?.newWidgetTheme,
    );
  }

  const showExtraFieldsInClientCabinet: boolean =
    businessData?.general_info.showExtraFieldsInClientCabinet ??
    defaultContext.showExtraFieldsInClientCabinet;


  const setUserTokenCookie = async () => {
    const cooReq = {
      jsonrpc: '2.0',
      cred: { user, token },
      method: 'misc.set_user_token_cookie',
      params: {},
      id: 1,
    };
    await fetch(ehrEndPoint, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cooReq),
    });
  }
  // if (patientId) {
  //   void setUserTokenCookie().catch(() => { })
  // }

  const refetchUser = () => {
    void refetchClient();
    void refetchProfile();
  }
  const clientContext: IClientContext = {
    clientId,
    clientInfo,
    clientInfoPending,
    patientId,
    businessId,
    networkId: businessNetworkID,
    contractId,
    contractExtraId,
    user,
    token,
    expires,
    ehrCounter,
    ehrCounterSupport: !!appEHRCountResult?.support,
    ehrEnabled,
    ehrEndPoint,
    ehrEntities,
    timezone: businessTimeZone,
    showExtraFieldsInClientCabinet,
    nationalMode,
    country,
    refetchClient: refetchUser,
    businessInfo: businessData,
    backofficeConfiguration: businessData?.backofficeConfiguration,
    generalInfo: businessData?.general_info,
    network: networkData,
    lang: lang || constants.LANGUAGES[0],
    theme,
    getResourceName: defaultContext.getResourceName,
  };

  if (businessData?.widget_configuration.middleNameSupport) {
    const { middleNameSupport } = businessData.widget_configuration;
    clientContext.getResourceName = ({ name, surname, middleName }: IgetResourceNameParam) => {
      if (middleNameSupport && middleName) {
        return `${surname} ${name} ${middleName}`;
      }
      return `${name} ${surname}`;
    };
  }
  return (
    <ClientContext.Provider value={clientContext}>
      <>
        <Global styles={() => globalStyles(theme)} />
        {children}
      </>
    </ClientContext.Provider>
  );
};
