import { BusinessGetNetworkDataRequestParams, ResultClass } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { useQuery } from '@tanstack/react-query';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { defaultTheme } from 'utils';

export const useGetNetwork = (
  networkID = '',
  contractID?: string,
  contractExtraId?: string,
): {
  error: unknown,
  data: Partial<ResultClass>,
  isFetching: boolean,
  isLoading: boolean,
} => {
  const params: BusinessGetNetworkDataRequestParams = {
    networkID: networkID,
  };
  if (contractExtraId) {
    params.contract = {
      extraId: contractExtraId,
    };
  } else if (contractID) {
    params.contract = {
      id: contractID,
    };
  }

  const { error, data: networkData, isFetching, isLoading } = useQuery({
    queryKey: ['network', networkID],
    queryFn: () => MedMeAPI.business.getNetworkData(params),
    staleTime: 0,
    retry: 0,
    enabled: !!networkID,
  });

  const clientCabinetTranslates = networkData?.result?.businessConfiguration?.newWidgetTheme?.clientCabinetTranslates ? JSON.stringify(networkData?.result?.businessConfiguration?.newWidgetTheme?.clientCabinetTranslates) : '{}'
  const newWidgetTheme = Object.assign(defaultTheme, { clientCabinetTranslates });

  const result: Partial<ResultClass> = {
    networkName: networkData?.result?.networkName ?? '',
    businessConfiguration: {
      ...networkData?.result?.businessConfiguration,
      newWidgetTheme: {
        ...newWidgetTheme,
        ...networkData?.result?.businessConfiguration.newWidgetTheme,
      },
    },
    integrationData: {
      ...networkData?.result?.integrationData,
    },
  }

  return { isLoading, error, data: result, isFetching }
}