import { Appointment } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { RefetchOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchAppointments } from './fetchAppointment';

export const useGetAppointments = (
  businessId = '',
  networkId = '',
  contractId?: string,
  contractExtraId?: string,
): {
  error: unknown,
  data?: Appointment[],
  isFetching: boolean,
  isLoading: boolean,
  refetch: (options?: RefetchOptions) => Promise<UseQueryResult>,
} => {
  const { error, data, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['appointments'],
    queryFn: () => fetchAppointments(businessId, networkId, contractId, contractExtraId),
    staleTime: 0,
    retry: 0,
  });
  return { isLoading, error, data, isFetching, refetch }
}