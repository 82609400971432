
import { MedMeAPI } from '@gbooking/ts-sdk';
import { initMedMeSDK } from 'utils';
import { BusinessRefInNetwork } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

initMedMeSDK();

export async function getNetworkBusinessList(
  id: number,
  contractExtraId: string,
): Promise<BusinessRefInNetwork[]> {
  const data = await MedMeAPI.business.getNetworkBusinessList(
    +id,
    true,
    undefined,
    contractExtraId,
  );
  return data;
}
