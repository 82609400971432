import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PageContainer } from '../components';
import { RouteComponentProps } from '@reach/router';

import { useTranslation } from 'react-i18next';
import { AppointmentInfoBlock } from 'components/AppointmentInfoBlock';
import { ClientContext, PostMessageProcessing, urlManager } from 'utils';
import { paths } from 'utils/routing';
import { Appointment } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { UserCred } from 'data-layer/queries/__graphql__/UserCred';
import { useGetAppointments } from 'shared/api/appointment';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppointmentsScreenProps extends RouteComponentProps {
  // eslint-disable-next-line react/require-default-props
  appointmentID?: string;
}

const AppointmentsScreen: React.FC<AppointmentsScreenProps> = ({
  appointmentID = '',
}: AppointmentsScreenProps) => {
  const { t } = useTranslation();
  const { clientInfo, user, token, expires } = useContext(ClientContext);

  const { data: appointments, refetch: refetchAppointments } = useGetAppointments(
    urlManager.getBusinessId(),
    urlManager.getNetworkId(),
    urlManager.getContractId(),
    urlManager.getContractExtraId(),
  );
  const [appointmentData, setAppointmentData] = useState<Appointment | undefined>();

  useEffect(() => {
    if(appointments) {
      setAppointmentData(appointments?.find((appointment: Appointment) => appointment.appointment.id === appointmentID));
    }
  }, [appointments])

  const client = clientInfo;
  const cred = useMemo<UserCred>(
    () => ({
      user,
      token,
      expires,
    }),
    [user, token, expires],
  );

  const pmConfig = useMemo(
    () => ({
      client,
      refetchApp: refetchAppointments,
      cred,
      visit: undefined,
    }),
    [client, refetchAppointments, cred],
  );

  useEffect(() => {
    const pm = new PostMessageProcessing(pmConfig);
    pm.subscribe();
    return () => {
      pm.unsubscribe();
    };
  }, [pmConfig]);
  
  const title =
    appointmentData?.taxonomy.alias || t('screens.appointments.noAppFound');

  if (!appointmentData) {
    return <PageContainer title={title}> no data</PageContainer>;
  }
  const selectedAppData: Appointment = appointmentData;

  const contentCSS = { padding: 0, overflow: 'hidden' };

  return (
    <PageContainer title={title} contentCSS={contentCSS} topNav backLink={paths.homePath}>
      <AppointmentInfoBlock appointmentData={selectedAppData} />
    </PageContainer>
  );
};

export default AppointmentsScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
