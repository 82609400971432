import * as Moment from 'moment';
import { extendMoment, DateRange } from 'moment-range';
import { IFilterItem } from 'components';
import { GetEHRAppointments_getPatientAppointments_appointments } from 'data-layer/queries/__graphql__/GetEHRAppointments';
import { GetEHRDiagnostics_getEHRDiagnostics_diagnostics } from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import { GetEHRAppointmentResults_getPatientAppointmentResults_appointmentResults } from 'data-layer/queries/__graphql__/GetEHRAppointmentResults';
import { Appointment } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

const moment = extendMoment(Moment);

export const getApiFilterValues = ({
  appointments,
  selectedResourcesKey,
  selectedServicesKey,
}: {
  appointments: (Appointment | null)[];
  selectedResourcesKey: string[];
  selectedServicesKey: string[];
}): {
  resources: IFilterItem[];
  taxonomies: IFilterItem[];
} => {
  const resList = new Map<string, IFilterItem>();
  const serviceList = new Map<string, IFilterItem>();
  appointments.forEach((app: Appointment | null) => {
    if (!app) {
      return;
    }
    const res: IFilterItem = {
      name: `${app.resource.surname} ${app.resource.name}`,
      key: `${app.resource.id}`,
      active: selectedResourcesKey.includes(`${app.resource.id}`),
    };
    if (res.key) {
      resList.set(res.key, res);
    }
    const s: IFilterItem = {
      name: `${app.taxonomy.alias}`,
      key: `${app.taxonomy.id}`,
      active: selectedServicesKey.includes(`${app.taxonomy.id}`),
    };
    serviceList.set(s.key, s);
  });
  return {
    resources: Array.from(resList.values()),
    taxonomies: Array.from(serviceList.values()),
  };
};

export const dateAppFilter = (
  app: Appointment | null,
  filterRanges: DateRange[],
): boolean => {
  return app && !!filterRanges.length
    ? filterRanges.some((range) => moment(app.appointment.start.toString()).within(range))
    : true;
};

export const dateEHRFilter = (
  app: GetEHRAppointments_getPatientAppointments_appointments,
  filterRanges: DateRange[],
): boolean => {
  return app && !!filterRanges.length
    ? filterRanges.some((range) => moment(app.start).within(range))
    : true;
};
export const dateEHRLabFilter = (
  app: GetEHRDiagnostics_getEHRDiagnostics_diagnostics,
  filterRanges: DateRange[],
): boolean => {
  return app && !!filterRanges.length
    ? filterRanges.some((range) => moment(app.issuedDate).within(range))
    : true;
};
export const dateEHRResultFilter = (
  app: GetEHRAppointmentResults_getPatientAppointmentResults_appointmentResults,
  filterRanges: DateRange[],
): boolean => {
  return app && !!filterRanges.length
    ? filterRanges.some((range) => moment(app.start).within(range))
    : true;
};

export const resourceEHRFilter = (
  app: GetEHRAppointments_getPatientAppointments_appointments,
  selectedResources: IFilterItem[],
): boolean => {
  return app && !!selectedResources.length
    ? selectedResources.map((s) => s.key).includes(app.doctor.id)
    : true;
};

export const resourceEHRResultFilter = (
  app: GetEHRAppointmentResults_getPatientAppointmentResults_appointmentResults,
  selectedResources: IFilterItem[],
): boolean => {
  return app && app.doctor?.id && !!selectedResources.length
    ? selectedResources.map((s) => s.key).includes(app.doctor.id)
    : true;
};
export const resourceEHRLabFilter = (
  app: GetEHRDiagnostics_getEHRDiagnostics_diagnostics,
  selectedResources: IFilterItem[],
): boolean => {
  const doctorIDs = app.result?.map((r) => r.performerDoctor?.id || '').filter((r) => !!r) || [];
  return app && !!selectedResources.length
    ? doctorIDs.some((d) => selectedResources.map((s) => s.key).includes(d))
    : true;
};

export const resourceAppFilter = (
  app: Appointment | null,
  selectedResources: IFilterItem[],
): boolean => {
  return app && !!selectedResources.length
    ? selectedResources.map((s) => s.key).includes(app.resource.id)
    : true;
};

export const serviceAppFilter = (
  app: Appointment | null,
  selectedServices: IFilterItem[],
): boolean => {
  return app && !!selectedServices.length
    ? selectedServices.map((s) => s.key).includes(app.taxonomy.id)
    : true;
};
/**
 *
 */
export const statusAppFilter = (
  app: Appointment | null
): boolean => {
  return app?.appointment?.status !== 'CANCELLED_BY_BUSINESS' && app?.appointment?.status !== 'CANCELLED_BY_CLIENT';
};

export const serviceEHRFilter = (
  app: GetEHRAppointments_getPatientAppointments_appointments,
  selectedServices: IFilterItem[],
): boolean => {
  const serviceIDs = app.services?.map((s) => s.id) || [];
  return app && !!selectedServices.length
    ? serviceIDs.some((serviceID) => selectedServices.map((s) => s.key).includes(serviceID))
    : true;
};

export const serviceEHRLabFilter = (
  app: GetEHRDiagnostics_getEHRDiagnostics_diagnostics,
  selectedServices: IFilterItem[],
): boolean => {
  const serviceIDs = app.services?.map((s) => s.id) || [];
  return app && !!selectedServices.length
    ? serviceIDs.some((serviceID) => selectedServices.map((s) => s.key).includes(serviceID))
    : true;
};
