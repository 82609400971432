/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Resolvers } from 'apollo-boost';

export const cacheRedirects: Resolvers = {
  Query: {
    getEhrAppointment: (_root, { id }: { id: string }, { getCacheKey }) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      getCacheKey({ __typename: 'PatientAppointment', id }),
    // getEHRDiagnosticResult: (_root, { visitId }: { visitId: string }, { getCacheKey }) => {
    //   // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    //   return getCacheKey({ __typename: 'PatientDiagnostic', id: `${visitId}` });
    // },
  },
};
