import { BusinessClass, BusinessGetProfileByIdRequestParams } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { useQuery } from '@tanstack/react-query';
import { MedMeAPI } from '@gbooking/ts-sdk';

export const useGetBusiness = (
  businessId = '',
): {
  error: unknown,
  data?: BusinessClass,
  isFetching: boolean,
  isLoading: boolean,
} => {
  const params: BusinessGetProfileByIdRequestParams = {
    business: {
      id: businessId,
    }
  }
  const { error, data, isFetching, isLoading } = useQuery({
    queryKey: ['business', businessId],
    queryFn: () => MedMeAPI.business.getProfileById(params),
    staleTime: 0,
    retry: 0,
  });
  return { isLoading, error, data: data?.result?.business, isFetching }
}