import { MedMeAPI } from '@gbooking/ts-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCancelAppointment = (): {
  isLoading: boolean,
  error: unknown,
  mutate: (appointmentID: string, shortId: string) => Promise<unknown>
} => {
  const queryClient = useQueryClient();
  const { isLoading, error, mutate } = useMutation({
    mutationKey: ['appointments', 'cancel'],
    mutationFn: ({ appointmentID, shortId }: {
      appointmentID: string,
      shortId: string,
    }) => MedMeAPI.appointment.cancelAppointmentByClient({
      appointment: { id: appointmentID, shortId },
    }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'appointments' || query.queryKey[1] === 'appointments',
      })
    },
  });
  const mutateSync = async (
    appointmentID: string,
    shortId: string,
  ) => {
    return new Promise((resolve, reject) => {
      mutate({appointmentID, shortId}, {
        onSuccess: (data) => {
          resolve(data);
        },
        onError: (error) => {
          reject(error);
        },
      });
    });
  };

  return { isLoading, error, mutate: mutateSync }
}