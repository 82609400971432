import { Appointment, AppointmentGetAppointmentsByUserRequestParams, Dir, SortField } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { MedMeAPI } from '@gbooking/ts-sdk';

export const fetchAppointments = (
  businessID?: string,
  networkID?: string,
  contractID?: string,
  contractExtraId?: string,
): Promise<Appointment[]> => {
  const params: AppointmentGetAppointmentsByUserRequestParams = {
    business: {
      id: businessID ?? '',
    },
    page: 0,
    pageSize: 150,
    fill_business_data: true,
    extraFilters: {
      sort: [
        {
          dir: 'desc' as Dir,
          field: 'start' as SortField,
        },
      ],
    },
  };
  if (contractExtraId) {
    params.contract = { extraId: contractExtraId };
  } else if (contractID) {
    params.contract = { id: contractID };
  }
  if (networkID) {
    params.network = {
      id: networkID,
    };
  }

  return MedMeAPI.appointment.getAppointmentByUser(params).then((res) => res.data)
}