//
import { ReactComponent as IconClock } from '../assets/icon-clock.svg';
import { ReactComponent as IconMedcard } from '../assets/icon-medcard.svg';
import { ReactComponent as IconCalendar } from '../assets/icon-calendar.svg';
import { ReactComponent as IconProfile } from '../assets/icon-profile.svg';
import { ReactComponent as IconBack } from '../assets/icon-back.svg';
//

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties, useContext } from 'react';
import { LocationProvider } from '@reach/router';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { navigate, useLocation, Link } from '@reach/router';
import Logo from './Logo';
import Layout from './Layout';
import UserPic from './UserPic';
import { breakpointLarge, breakpointSmall, hideForMobile, showForMobile, size, unit } from '../styles';
import { urlManager } from 'utils/urlManager';

import { ClientContext, getAvatar } from 'utils';
import { paths } from 'utils/routing';
import { Stack } from '../shared/ui/Stack';

interface IPageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  layoutClassName?: string;
  contentCSS?: CSSProperties | undefined;
  title?: string;
  topNav?: boolean;
  backLink?: string;
  children?: React.ReactNode;
  onBackClick?: () => void;
}

export default function PageContainer({
  className = undefined,
  layoutClassName = undefined,
  children = undefined,
  title = '',
  topNav = false,
  backLink = undefined,
  contentCSS = undefined,
  onBackClick = undefined,
}: IPageContainerProps): JSX.Element {
  const { t } = useTranslation();
  const networkID = urlManager.getNetworkId();
  const businessID = urlManager.getBusinessId();
  const { clientInfo, theme, ehrEnabled, businessInfo: businessData, network: networkData } = useContext(ClientContext);

  const queryString = urlManager.getQueryString();
  
  /*
  export const ehrAppointmentResultPath = (id: string): string => `${ehrAppointmentResultsPath}/${id}`;
export const ehrDiagnosticPath = (id: string): string => `${ehrDiagnosticsPath}/${id}`;
  */
  const tabs = {
    myRecords: {
      name: t('components.topNav.myRecords'), icon: <IconClock height="16"/>,
      path: paths.homePath, queryString,
      isSelected: (path: string) => path == paths.homePath
    },
    record: {
      name: t('components.topNav.record'), icon: <IconCalendar height="16"/>,
      path: paths.newRecordPath, queryString,
      isSelected: (path: string) => path == paths.newRecordPath
    },
    ehr: {
      name: t('components.topNav.ehr'), icon: <IconMedcard height="16"/>,
      path: paths.ehrPath, queryString,
      isSelected: (path: string) => path == paths.ehrPath || [
        paths.ehrAppointmentPath(''), paths.ehrAppointmentResultPath(''), paths.ehrDiagnosticPath('')
      ].some(v => path.startsWith(v))
    },
    profile: {
      name: t('components.topNav.profile'), icon: <IconProfile height="16"/>,
      path: paths.profileInfoPath, queryString,
      isSelected: (path: string) => path == paths.profileInfoPath
    },
  }
  
  return (
    <LocationProvider>
      <Layout className={layoutClassName} theme={theme}>
      {backLink && <BackLink to={backLink + '?' + queryString}/>}
      {backLink && <BackLinkMobile to={backLink + '?' + queryString}/>}
      {topNav && (
        <AppHeader hasBackLink={!!backLink}>
          <Stack direction='row' spacing={2}>
            <Logo
              url={urlManager.getLogoUrl({
                networkID,
                businessID,
                networkData,
                businessData,
              })}
            />
            <Tabs list={[tabs.myRecords, ...(ehrEnabled ? [tabs.ehr] : []), ...[tabs.record]]}/>
          </Stack>
          <UserLinkStyled to={`${paths.profileInfoPath}?${queryString}`}>
            <UserPic src={getAvatar(clientInfo.iconURL)} />
          </UserLinkStyled>
        </AppHeader>
      )}
      {topNav && <AppHeaderMobile>{title}</AppHeaderMobile>}
      <AppNavMobile>
        <MobileTabs
          list={[
            { ...tabs.myRecords, icon: <IconClock height="18"/> },
            { ...tabs.record, icon: <IconCalendar height="18"/> },
            ...(ehrEnabled ? [
              { ...tabs.ehr, icon: <IconMedcard height="18"/> }
            ] : []),
            { ...tabs.profile, icon: <IconProfile height="18"/> },
          ]}
        />
      </AppNavMobile>
      <Container className={className}>
        <Content style={contentCSS}>
          {children}
        </Content>
      </Container>
    </Layout>
  </LocationProvider>
  );
}

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
 
const AppHeader = styled('div')(
  hideForMobile,
  ({ hasBackLink }: { hasBackLink: boolean }) => ({
    width: '100%',
    height: '64px',
    background: '#F5F5F5',
    display: 'flex',
    marginBottom: hasBackLink ? '15px' : 0,
    justifyContent: 'space-between',
  })
);

const BackLink = [styled(Link)(hideForMobile, {
  width: '100px',
  cursor: 'pointer',
  textDecoration: 'none',
  userSelect: 'none',
  color: '#222222',
  zIndex: 2,
  height: '45px',
  // border: '1px solid #cfcfcf',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  position: 'absolute',
  top: '60px',
  left: '15px'
})].map(Component => ({ to }: { to: string }) =>
  <Component to={to}>
    <IconBack height="18"/>
    { useTranslation().t('button.back') }
  </Component>)[0]

const BackLinkMobile = [styled(Link)(showForMobile, {
  width: '45px',
  cursor: 'pointer',
  userSelect: 'none',
  textDecoration: 'none',
  color: '#222222',
  zIndex: 2,
  height: '45px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  position: 'absolute',
  top: '0px',
  left: '0px',
  path: {
    fill: 'white'
  }
})].map(Component => ({ to }: { to: string }) => <Component to={to}>
  <IconBack height="18"/>
</Component>)[0]

const AppHeaderMobile = styled('div')(showForMobile, {
  textAlign: 'center',
  padding: '1px 12px 2px',
  height: '45px',
  width: '100%',
  background: '#47C4E4',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '45px',
  color: '#FFFFFF'
});

const AppNavMobile = styled('div')(showForMobile, {
  height: size.sideNavHeightMobile,
  position: 'absolute',
  display: 'flex',
  zIndex: 1,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'white',
  borderTop: '1px solid #eeeeee'
});

interface ITabsItem {
  path: string, queryString: string, name: string,
  icon: React.ReactNode, isSelected: (arg: string) => boolean
};
const TabsItem = styled('div')(({ selected }: { selected: boolean }) => ({
  padding: '20px',
  background: selected ? '#47C4E4' : 'white',
  color: selected ? 'white' : '#47C4E4',
  display: 'flex',
  cursor: 'pointer',
  margin: "7px",
  borderRadius: "10px",
  fontWeight: 500,
  boxShadow: "3px 3px 6px 3px rgb(12 6 21 / 9%)",
  alignItems: 'center',
  gap: '8px',
  '> *': {
    flexShrink: 0
  },
}));
const Tabs = ({ list }: { list: ITabsItem[] }) => {
  const location = useLocation();
  return <>{list.map((item, i) =>
    <TabsItem
      key={i}
      selected={item.isSelected(location.pathname)}
      onClick={() => navigate(`${item.path}?${item.queryString}`)}
    >
      {item.icon}
      <div>{item.name}</div>
    </TabsItem>
  )}</>;
}

const MobileTabsItem = styled('div')(({ selected, tabsCount }: { selected: boolean, tabsCount: number }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 100 / tabsCount + '%',
  color: selected ? '#30B7D9' : '#999999',
  fontSize: '11px',
  padding: '4px',
  cursor: 'pointer',
  alignItems: 'center',
  '> *': {
    flexShrink: 0
  },
  '> div': {
    marginTop: '2px'
  }
}));
const MobileTabs = ({ list }: { list: ITabsItem[] }) => {
  const location = useLocation();
  return <>{list.map((item, i) =>
    <MobileTabsItem
      key={i}
      tabsCount={list.length}
      selected={item.isSelected(location.pathname)}
      onClick={() => navigate(`${item.path}?${item.queryString}`)}
    >
      {item.icon}
      <div>{item.name}</div>
    </MobileTabsItem>
  )}</>;
}

const columnStyle = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
});

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  marginTop: '24px',
  flexGrow: 1,
});

const Content = styled('div')(columnStyle, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
  padding: `0 ${unit * 3}px`,
  [`[dir="ltr"] &`]: {
    left: 0,
  },
  [`[dir="rtl"] &`]: {
    right: 0,
  },
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    [`[dir="ltr"] &`]: {
      right: 0//sideNav ? size.sideNavWidth : 0,
    },
    [`[dir="rtl"] &`]: {
      left: 0//sideNav ? size.sideNavWidth : 0,
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    margin: '0',
    bottom: size.sideNavHeightMobile,
    [`[dir="ltr"] &`]: {
      right: 0,
    },
    [`[dir="rtl"] &`]: {
      left: 0,
    },
  },
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    paddingLeft: unit * 2,
    paddingRight: unit * 2,
  },
  [`@media screen and (max-height: ${unit * 58}px)`]: {
    overflow: 'visible',
  },
});

const UserLinkStyled = styled(Link)({
  '> div': {
    width: 40,
    height: 40,
  },
  margin: '12px 12px 16px 12px'
});
