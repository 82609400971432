import React from 'react';
import styled from '@emotion/styled';
import { ITheme } from 'data-layer/types';
import { breakpointLarge, unit } from 'styles';
import { ReactComponent as IconDelete } from '../../assets/icon-delete.svg';
import { DownloadAndViewFileButton } from './DownloadAndViewFileButton';
import { uploadFile } from 'data-layer/resolvers';
import { IconButton } from 'shared/ui/IconButton';
import { Stack } from 'shared/ui/Stack';
import { UseFormRegisterReturn } from 'react-hook-form';
import { AvailableExtraField } from './types';

type ExtraFieldFileProps = {
  availableExtraField: AvailableExtraField;
  register: UseFormRegisterReturn;
  setExtraField: (extraField: AvailableExtraField) => void;
  watchExtraField: () => AvailableExtraField;
};

export const ExtraFieldFileInput: React.FC<ExtraFieldFileProps> = ({
  availableExtraField,
  register,
  setExtraField,
  watchExtraField,
}: ExtraFieldFileProps) => {
  const extraField = watchExtraField();

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    if (files && files[0]?.name) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      const fileTokenUpload: string = await uploadFile(files[0]);
      if (fileTokenUpload) {
        setExtraField({
          ...availableExtraField,
          value: fileTokenUpload,
          fileName: files[0].name,
        });
      }
    }
  };
  return extraField.value ? (
    <Stack
      spacing={2}
      direction={'row'}
      sx={{
        [`@media screen and (max-width: ${breakpointLarge}px)`]: {
          justifyContent: 'center',
        },
      }}
    >
      <DownloadAndViewFileButton availableExtraField={extraField} />
      {availableExtraField.availableForEditing && (
        <IconButton
          type="button"
          iconSize={2}
          onClick={() =>
            setExtraField({
              ...availableExtraField,
              value: undefined,
              fileName: undefined,
            })
          }
        >
          <IconDeleteStyled />
        </IconButton>
      )}
    </Stack>
  ) : (
    <input {...register} type="file" multiple={false} onChange={onFileChange} />
  );
};

const IconDeleteStyled = styled(IconDelete)((props: { theme: ITheme }) => ({
  stroke: props.theme.mainColor,
  width: (unit - 1) * 2,
  height: (unit - 1) * 2,
}));
