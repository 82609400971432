import { ExtraField } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

export type ExtraFieldsFormType = {
  extraFields: AvailableExtraField[];
};

export type AvailableExtraField = {
  id: string;
  name: string;
  value?: string;
  fileName?: string;
  availableForEditing: boolean;
  fieldType: string;
};

export const transformAvailableExtraFieldToExtraField = (
  availableExtraField: AvailableExtraField
): ExtraField => {
  return {
    fieldID: availableExtraField.id,
    fieldName: availableExtraField.name,
    value: availableExtraField.value || null,
    name: availableExtraField.fileName || null,
  };
};
