import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IsLoggedIn } from 'pages/IsLoggedIn';
import { AuthProvider, ClientContextProvider, ContentDirection, WidgetModal } from 'components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initMedMeSDK } from 'utils';

initMedMeSDK();

const App: React.FC = () => {
  const queryClient = new QueryClient();
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ClientContextProvider>
            <ContentDirection />
            <IsLoggedIn />
            <WidgetModal />
          </ClientContextProvider>
        </AuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
